body {
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
  font-family: helvetica, arial, verdana, sans-serif;
}

.wrap-table {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.wrap-table div {
  font-size: 15px;
}

.wrap-settings {
  padding: 15px 0 0 30px;
}

.wrap-settings .btn-filter {
  margin: 10px 0;
  border-radius: 4px;
  max-width: 100px;
}

.wrap-settings .btn-filter:hover,
.wrap-settings .btn-filter:focus {
  background: #007bff;
}

.register-form {
  width: 90%;
  max-width: 1250px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #337ab7;
  padding: 15px 0;
  margin: 15px 0;
}

.register-form input {
  margin: 5px 0;
}

.register-form input[type="submit"] {
  font-weight: bold;
  font-size: 14px;
  max-width: 350px;
  width: 60%;
  align-self: center;
}

.register-form h3 {
  font-size: 20px;
  font-weight: bold;
}

.wrap-form {
  flex-direction: column;
  font-size: 13.5px !important;
  text-align: right;
  max-width: 100%;
}

.error-filtervalue,
.error-clientName,
.error-email {
  color: red;
  height: 0;
  transition: color 0.2s ease-in-out;
}

.error-clientName,
.error-email {
  visibility: hidden;
}

#filter-value {
  margin: 0 13px;
  padding: 0 5px;
}

.modal-window {
  min-width: 300px;
  min-height: 100px;
  font-size: 15px;
}

.header-modal {
  padding: 0 15px;
}

.ReactModal__Content .ReactModal__Content--after-open {
  transition: all 2s;
}

.add-info {
  padding: 10px;
  background-color: rgba(70, 206, 209, 0.3);
  text-shadow: 0.4px 0px 0 #007bff;
}

.ReactTable {
  width: 90%;
  max-width: 1200px;
  border: 1px solid rgba(70, 130, 180, 0.4);
  box-shadow: 0px 0px 10px rgba(70, 130, 180, 0.4);
  margin: 30px 0;
}

.ReactTable .-even {
  background-color: rgba(70, 130, 180, 0.2)
}

.ReactTable .-odd {
  background-color: white !important;
}

.-header {
  font-weight: bold;
  font-size: 15px;
}

.ReactTable .active-row {
  background-color: lightgray !important;
  box-shadow: 0 0 3px gray;
  border: 1px solid darkgray;
}

.ReactTable .-btn {
  background-color: rgba(70, 130, 180, 0.8) !important;
}

.ReactTable .-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: normal !important;
}

.ReactTable .convertTime,
.ReactTable .differentTime {
  text-align: center;
}

.checkbox {
  margin: 0 auto !important;
}

.ReactTable btn[disabled] {
  opacity: 0.1 !important;
}

.rt-noData {
  z-index: auto !important;
}

@media(max-width:475px) {
  .wrap-form {
    align-items: center;
  }

  .wrap-form h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  .wrap-form label {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .wrap-form .error-clientName,
  .wrap-form .error-email {
    text-align: center;
  }
}

@media (max-width: 560px) {
  .wrap-settings form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wrap-settings #filter-value {
    max-width: 80px;
  }

  .ReactTable {
    margin-top: 0;
  }
}